.login-view,
.forgot-password-view,
.set-password-view,
.change-email-view {
  width: 100%;

  .header-img {
    width: 100%;
  }

  .background {
    height: 120vh;
    top: 0;
    right: 0;
    background-image: linear-gradient($gradient-start, $gradient-end);
    display: flex;
    flex: 50%;
  }

  .form {
    display: flex;
    flex: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .background-wrapper {
      max-width: 600px;
      margin: 0 50px;
      border-radius: 14px;
    }
  }

  h2 {
    color: $dark-blue;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: 30px;
  }

  h4 {
    color: white;
  }

  h2,
  h4 {
    font-family: $avenir-next-bold;
  }

  .dark-blue-btn {
    margin-top: 37px !important;
  }

  .el-input {
    border-radius: 7px;
    background-color: #ffffff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
  }

  .el-button.is-disabled {
    background: $disabled-btn;

    &:hover {
      background: $disabled-btn;
    }
  }

  .background-wrapper {
    background: white;
    padding-bottom: 50px;
  }
}
