// Colors
$primary: #266fca;
$secondary: #191919;
$body-background: #f0f2f8;

$white: #fff;
$gray: #adb5bd;
$red: #e41e26;
$tundora: #4e4e4e;

$light-gray: #abbbf8;
$lighter-gray: #e1e2eb;

$warning: #ff8d13;
$yellow: #e9b50e;

$gradient-start: #2564f0;
$gradient-end: #3d38ed;

$dark-blue: #061058;
$pale-blue: #abbbf8;
$disabled-btn: #b1b5c3;
$green-btn: #00984b;
$gray-btn: #c3c3c3;
$blue-btn: #3050ef;
$danger-btn: #fe3644;
$device-info-btn: #ffbd03;

$completed-green: #14d75b;
$canceled-red: #e41e26;
$pending-yellow: #ffd666;

$alizarin-crimson-transparent: #e41e2636;
$perano-transparent: #abbbf866;
$blue-transparent: #cbd0dc99;

$tab-view-color: #2563f0;
$carousel-arrow-color: #264ae8;
$pop-up-close-bg: #cbd0dc;
// Fonts
$avenir-next-bold: "AvenirNextLTProBold";
$avenir-next-demi: "AvenirNextLTProDemi";
$avenir-next-regular: "AvenirNextLTProRegular";
// $font-size-base: 0.875rem;
