

.add-billing-address {
  padding: 24px 0;

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
