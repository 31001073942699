
.set-password-view {
  h4 {
    color: rgba(6, 16, 88, 0.6) !important;
    font-family: Avenir, sans-serif !important;
    font-size: 16px !important;
    letter-spacing: 0 !important;
    line-height: 22px !important;
  }
}
