// CUSTOM HR TAG
.hr-custom {
  height: 1px;
  background: #b4b7cc;
  width: 100%;
  display: block;
  opacity: 0.4;
}

.dark-blue-btn,
.darker-blue-btn,
.green-btn,
.gray-btn {
  height: 50px;
  width: fit-content;
  padding: 12px 30px !important;
  border-radius: 25px !important;
  background: linear-gradient(
    180deg,
    $gradient-start 0%,
    $gradient-end 99.97%
  ) !important;

  span {
    color: $white;
    font-family: $avenir-next-demi;
    letter-spacing: 0.12px;
    line-height: 19px;
    font-size: 16px;
  }

  span > h4 {
    font-weight: 600 !important;
  }
}

.flat-btn {
  padding: 9px 27px !important;
  height: unset;

  span {
    font-size: 14px !important;
  }
}

.pop-up-btn {
  height: 40px;
  width: fit-content;
  box-shadow: 0 10px 20px 0 $perano-transparent;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    $gradient-start 0%,
    $gradient-end 100%
  ) !important;
  padding: 0 34px !important;
}

.gray-btn {
  background: $gray-btn !important;
  box-shadow: none;
  line-height: 22px;
  height: 40px;
  width: fit-content;
  padding: 0 30px;
}

.darker-blue-btn {
  max-width: 220px !important;
  background: $dark-blue !important;
}

.green-btn {
  background: $green-btn !important;
}

.cancel-btn {
  max-width: 183px;
  height: 50px;
  width: 100%;
  border-radius: 25px !important;
  background: $lighter-gray !important;
}

.begin-maintenance-btn {
  width: fit-content;
  padding: 0 9px;
  height: 36px;
  border-radius: 7px;
  border: none;
  background: $blue-btn;
  color: $white;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1px;
}

@mixin red-btn {
  max-width: 160px;
  height: 40px;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 $alizarin-crimson-transparent;
  color: $white !important;
  background: $red !important;
}

.border {
  border: 1px solid $lighter-gray;
}

.cursor-pointer {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
