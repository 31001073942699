
@import "@variables";

.view-bike-details {
  .load-more-btn {
    color: $primary;
  }

  .begin-maintenance-btn {
    line-height: 22px;
    width: fit-content;
    padding: 0 9px !important;
    border-radius: 7px !important;
  }
}
