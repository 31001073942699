
@media only screen and (max-width: 600px) {
  .responsive {
    display: flex;
    flex-direction: column !important;

    .el-col {
      width: 100%;
    }
  }
}

.grid-buttons{
  display: inline-grid;
  grid-template-rows: 5px 5px;
  grid-template-columns: 120px 5px;
  grid-gap: 10px;
}

a.disabled {
  pointer-events: none;
  color: gray
}

