
@import "@variables";

.login-view {
  display: flex;

  a {
    color: $dark-blue;
    font-family: "Avenir Next", sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 19px;
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
      flex-direction: column;
      margin-top: 100px;
  }
}

.add-billing-address {
  padding: 24px 0;

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
