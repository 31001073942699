
.view-ticket-details {
  padding-bottom: 24px;

  .carousel-not-available {
    height: 200px;
    align-items: center;
  }

  .col {
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .carousel {
    position: relative;
    margin-top: 16px;
    margin-bottom: 20px;

    .arrows {
      position: absolute;
      font-size: 28px;
      cursor: pointer;
    }

    .arrow-left,
    .arrow-right {
      top: 50%;
      transform: translateY(-50%);
    }

    .arrow-right {
      right: 0;
    }

    .arrow-left {
      left: 0;
    }

    .carousel-img {
      height: 200px;
      width: 270px;
      object-fit: cover;
    }
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    margin: 0;
  }

  .el-carousel__item {
    height: 200px;
    width: 270px;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}

.status-wrapper {
  display: flex;
  align-items: center;
}
